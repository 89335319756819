import React from "react";
import FD from "../../../assets/images/fixed-deposit.png";
import Term from "../../../assets/images/term-insurance.png";
import Child from "../../../assets/images/child-education.png";
import Recurring from "../../../assets/images/recurring.png";
import SIP from "../../../assets/images/sip.png";
import Retirement from "../../../assets/images/retirement.png";
import IncomeImg from "../../../assets/images/income-tax.png";
import CagrImg from "../../../assets/images/compound.png";
import Mutual from "../../../assets/images/mutual.png";
import PresetMoney from "../../../assets/images/current-account.png";
import FutureMoey from "../../../assets/images/future.png";
import Health from "../../../assets/images/health.png";
import PPF from "../../../assets/images/ppf.png";
import IRR from "../../../assets/images/budget.png";


import BI_calculator from "../../../assets/images/BI_calculator.png";

import Financial_health_review from "../../../assets/images/financial_health_review.png"
import Income_tax from "../../../assets/images/income_tax.png"
import shubmuhurt from "../../../assets/images/kumbh-kalash.png"



import "./home.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

export default function Home() {
  let navigate = useNavigate();


  function funbiAction(name,urlgo){
    let jsondatata= JSON.parse(sessionStorage.getItem("user"))
     axios.post("https://api.calculator.taction.in/api/api/v1/add_user_id", {
      user_id:jsondatata.vdi_____________tmp,calculator_name:name
    })
     .then(()=>{
      function hasTouchSupport() {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
      }
      
      if (hasTouchSupport()) {
         window.location.href=urlgo
      } else {
        window.open(urlgo,"_blank")
      }
       
     })
     .catch((error) => {

      function hasTouchSupport() {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
      }
      
      if (hasTouchSupport()) {
         window.location.href=urlgo
      } else {
        window.open(urlgo,"_blank")
      }
       


    })
  
  }



  useEffect(()=>{
    // console.log()
  },[])

  return (
    <div className="container-fluid pt-0 pb-5">
      <div className="col-md-12 pt-0" style={{ margin: "auto" }}>
        <div className="row pt-0">
          {/* <div className="path-text mx-md-3"> Calculator</div> */}
          <div className="mt-4 fd-cal-text mb-3 mx-md-3"></div>
          <div className="col-3 col-lg-2  d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/fd-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                Fixed Deposit 
                  <br />
                  Calculator
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={FD} alt="" />
            </div>
          </div>
          <div className="col-3 col-lg-2  d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/term-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                  Term Insurance
                  <br />
                  Calculator
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={Term} alt="" />
            </div>
          </div>
          <div className="col-3 col-lg-2  d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/child-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                  Child Education
                  <br />
                  Calculator
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={Child} alt="" />
            </div>
          </div>
          <div className="col-3 col-lg-2  mt-0 mt-lg-0 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/rd-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                  RD
                  <br />
                  Calculator
                </div>
              </div>
              <img
                className="card-img-top mb-lg-4 mb-3"
                src={Recurring}
                alt=""
              />
            </div>
          </div>
          <div className="col-3 col-lg-2 mt-3 mt-md-3 mt-lg-0 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/sip-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                  SIP
                  <br />
                  Calculator
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={SIP} alt="" />
            </div>
          </div>
          <div className="col-3 col-lg-2  mt-md-3 mt-lg-0 mt-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/retirment-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                  Retirement
                  <br />
                  Calculator
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={Retirement} alt="" />
            </div>
          </div>
          <div className="col-3 col-lg-2 mt-3  mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/ppf-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                  PPF
                  <br />
                  Calculator
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={PPF} alt="" />
            </div>
          </div>

          
          {/* <div className="col-3 col-lg-2 mt-3 mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/incometax-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                  Income Tax
                  <br />
                  Calculator
                </div>
              </div>
              <img
                className="card-img-top mb-lg-4 mb-3"
                src={IncomeImg}
                alt=""
              />
            </div>
          </div> */}

          <div className="col-3 col-lg-2 mt-3 mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/cagr-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                  CAGR
                  <br />
                  Calculator
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={CagrImg} alt="" />
            </div>
          </div>
          {/* <div className="col-3 col-lg-2  mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/mutual-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                  Mutual Fund <br />
                  Calculator
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={Mutual} alt="" />
            </div>
          </div> */}

          <div className="col-3 col-lg-2 mt-3 mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/future-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                Future Value  <br />
                 Calculator
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={FutureMoey} alt="" />
            </div>
          </div>
          <div className="col-3 col-lg-2 mt-3 mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/present-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                Present Value  <br />
                Calculator 
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={PresetMoney} alt="" />
            </div>
          </div>     
          <div className="col-3 col-lg-2 mt-3 mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/medical-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                Health<br />
                 Calculator
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={Health} alt="" />
            </div>
          </div>
          <div className="col-3 col-lg-2 mt-3 mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/irr-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                IRR<br />
                 Calculator
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={IRR} alt="" />
            </div>
          </div>



          <div className="col-3 col-lg-2 mt-3 mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                funbiAction("Business Insurance","https://resources.tataaiapartner.com/landingpage/BI-Calculator-new")
                // navigate("/irr-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                Business Insurance<br />
                   Calculator                 
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={BI_calculator} alt="" />
            </div>
          </div>




          <div className="col-3 col-lg-2 mt-3 mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {

                funbiAction("Financial Health Review Calculator","https://resources.tataaiapartner.com/landingpage/evaluate-your-financial-health-open")
                // navigate("/irr-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                Financial Health Review<br />
                   Calculator                 
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={Financial_health_review} alt="" />
            </div>
          </div>




          <div className="col-3 col-lg-2 mt-3 mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {

                funbiAction("Income Tax Calculator","https://resources.tataaiapartner.com/landingpage/income_tax_calculator-open")
                // navigate("/irr-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                Income Tax<br />
                   Calculator                 
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={Income_tax} alt="" />
            </div>
          </div>
          
          <div className="col-3 col-lg-2 mt-3 mt-md-3 d-flex justify-content-center text-center">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => {

                funbiAction("Shubh Muhurat Calculator","https://www.tataaia.com/calculator/wedding-budget-calculator.html")
                // navigate("/irr-calulator");
              }}
            >
              <div className="card-body">
                <div className="card-text mt-xl-3">
                Shubh Muhurat <br />
                   Calculator                 
                </div>
              </div>
              <img className="card-img-top mb-lg-4 mb-3" src={shubmuhurt} alt="" />
            </div>
          </div>


          
          



        </div>
      </div>
    </div>
  );
}
