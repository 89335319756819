
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../src/views/components/header/header'
import Tabs from '../src/views/components/tabs/tabs'
import Home from './views/pages/home-calculator/home';
import FDCalculator from './views/pages/FD/fdcalculator';
import Term from './views/pages/term/term';
import ChildEducation from './views/pages/child-education/child-education';
import RDCalculator from './views/pages/RD/rdcalculator';
import SPCalculator from './views/pages/SIP/sipcalculator';
import Retirement from './views/pages/retirement/retirement';
import PPFCalculator from './views/pages/PPF/ppf';
import IncomeTax from './views/pages/income-tax/income-tax';
import CAGRCalculator from './views/pages/CAGR/Cagr';
import MutualFundCal from './views/pages/MutualFund/MutualFund';
import FutureValueCalculator from './views/pages/FutreValuePresentMoney/FutreValuePresentMoney';
import PresentValueFutureMoney from './views/pages/PresentValueFutureMoney/PresentValueFutureMoney';
import Health from './views/pages/Health/Health';
import { Toaster } from 'react-hot-toast';
import Calculated from './views/pages/Health/Calculated';
import CalculatorSpeech from './views/pages/Health/Calculator-speech';
import IRRCalculator from './views/pages/IRR/irr';
import Modal from 'react-modal';
import './App.css';
import axios from 'axios';




function App() {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "400px",
    },
  };


  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(true);
  const [error, setError] = useState('');
  const [VID, setVID] = useState("")


  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }
  function isAlphaNumeric(str) {
    let code, i, len;

    let numstate=false
    let alfastate=false


  
    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
if(code > 47 && code < 58){
  numstate=true
}
if(code > 64 && code < 91 || code > 96 && code < 123){
  alfastate=true
}
    }
    return numstate && alfastate
  };


  function add_user_id() {
    if (VID == "") {
      setError("Enter your VAcademy login ID")
      return
    }
    else if (VID.length > 16) {
        setError("VAcademy ID cannot be more than 16 characters")
      return
    }

    axios.post("https://api.calculator.taction.in/api/api/v1/add_dashboard_api", {
      user_id: VID
    })
      .then((response) => {
        try{
       let jsondatata= JSON.parse(sessionStorage.getItem("user"))
       jsondatata.vdi_____________tmp=VID
        sessionStorage.setItem("user",JSON.stringify(jsondatata))
      }
      catch(e){
        sessionStorage.setItem("user",JSON.stringify({vdi_____________tmp:VID}))
      }
        closeModal()
      })
      .catch((error) => {
        
        try{
          let jsondatata= JSON.parse(sessionStorage.getItem("user"))
          jsondatata.vdi_____________tmp=VID
           sessionStorage.setItem("user",JSON.stringify(jsondatata))
         }
         catch(e){
           sessionStorage.setItem("user",JSON.stringify({vdi_____________tmp:VID}))
         }
           closeModal()


  })

  }
  useEffect(() => {

    // let jsondatata= JSON.parse(sessionStorage.getItem("user"))
    // if (jsondatata?.vdi_____________tmp == null || jsondatata?.vdi_____________tmp == undefined) {
    //   setIsOpen(true)
    // }
   

  }, [])

  return (
    <>




      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <div style={{ fontWeight: "600", fontSize: "20px", textAlign: "center", color: "#051464" }} className=' label_____ '>
          Please confirm your VAcademy login ID <span style={{ color: "red" }}> *</span>
        </div>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <input type="text" style={{ width: "300px", borderRadius: "3px" }}
            value={VID}
            placeholder="Enter your VAcademy login ID" onInput={(event) => {
              setVID(event.target.value)
            setError("")
            }} />

        </div>
        <div style={{ textAlign: "left", }}>
          <span style={{ color: "red", marginLeft: "31px" }} className=' spanError '>{error}</span>
        </div>


        <div style={{ textAlign: "center", marginTop: "12px" }}>
          <button className=" btn mx-2 " style={{ width: "100px", backgroundColor: "rgba(17,115,176,255)", color: "white" }} onClick={() => {
            add_user_id()
          }}>

            Submit
          </button>

          {/* <button className=" btn mx-2 " style={{width:"100px",border:"1px solid rgba(17,115,176,255)",color:"rgba(17,115,176,255)"}}
                            onClick={closeModal}
                            
                            
                            
                            
                            >    

Close
</button> */}

        </div>









      </Modal>


      {/* <Header /> */}
      <Routes>
        {/* <Tabs/> */}
        <Route exact path='/' element={< Home />}></Route>
        <Route exact path='/fd-calulator' element={< FDCalculator />}></Route>
        <Route exact path='/term-calulator' element={< Term />}></Route>
        <Route exact path='/child-calulator' element={< ChildEducation />}></Route>
        <Route exact path='/rd-calulator' element={< RDCalculator />}></Route>
        <Route exact path='/sip-calulator' element={< SPCalculator />}></Route>
        <Route exact path='/retirment-calulator' element={< Retirement />}></Route>
        <Route exact path='/ppf-calulator' element={< PPFCalculator />}></Route>
        <Route exact path='/incometax-calulator' element={< IncomeTax />}></Route>
        <Route exact path='/cagr-calulator' element={< CAGRCalculator />}></Route>
        <Route exact path='/mutual-calulator' element={< MutualFundCal />}></Route>
        <Route exact path='/future-calulator' element={< FutureValueCalculator />}></Route>
        <Route exact path='/present-calulator' element={< PresentValueFutureMoney />}></Route>
        <Route exact path='/medical-calulator' element={< Health />}></Route>
        <Route exact path='/medical-calculated' element={< Calculated />}></Route>
        <Route exact path='/medical-speech' element={< CalculatorSpeech />}></Route>
        <Route exact path='/irr-calulator' element={< IRRCalculator />}></Route>
      </Routes>
    </>
  );
}
// irr-calulator
export default App;
