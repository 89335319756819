import React, { useEffect, useState } from "react";
import "./irr.css"
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Chart from 'react-apexcharts'
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";
import AddMore from "../../../assets/images/plus.png"
import Remove from "../../../assets/images/cross.png"

export default function FDCalculator() {
  const navigate = useNavigate()
  const [initialValue, setinitialValue] = React.useState(0);
  const [investFlag, setInvestFlag] = React.useState(false);
  const [investErr, setInvestErr] = React.useState("");
  const [cashFlow, setcashFlow] = useState([{cashflow:0}])
  const [cashFlowFlag, setcashFlowFlag] = useState(false)
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [ratetFlag, setRateFlag] = React.useState(false);
    const [ratetErr, setRateErr] = React.useState("");
    const [rate, setRate] = React.useState(0.1);
  const handleChangeComplete = () => {
  
  }

  const RemoveMoreCashFlow = (index, e) => {
    e.preventDefault();
    const list = [...cashFlow];
    list.splice(index, 1);
    setcashFlow(list);
  };

  const addMoreCashflow = (e) => {
    e.preventDefault();
    setcashFlow([
      ...cashFlow,
      {
        cashflow:0
      },   
    ]);
  };


  const calculateIRR = (cashFlow, initialValue, guess = 0.1, maxIterations = 1000, tolerance = 1e-6) => {
    let irr = guess;
  
    for (let i = 0; i < maxIterations; i++) {
      let npv = -initialValue; // Start with initial investment as negative cash flow
      let derivative = 0; // Derivative for Newton-Raphson method

      for (let t = 0; t < cashFlow.length; t++) {
        npv += cashFlow[t] / Math.pow(1 + irr, t + 1);
        derivative -= (t + 1) * cashFlow[t] / Math.pow(1 + irr, t + 2);
      }

      // Prevent division by zero
      if (Math.abs(derivative) < tolerance) {
        setError("Unable to calculate the Internal Rate of Return based on provided data.");
        return null;
      }

      const newIrr = irr - npv / derivative;

      // Check for convergence
      if (Math.abs(newIrr - irr) < tolerance) {
        return newIrr * 100; // Convert to percentage
      }

      irr = newIrr;
    }

    // If no convergence, return error
    setError("Unable to calculate the Internal Rate of Return based on provided data.");
    return null;
  };

  const handleCalculate = () => {
    setError(null); // Clear previous errors
    setInvestFlag(false);

    if (initialValue === "" || initialValue === 0) {
      setError("Initial value is required");
      setInvestFlag(true);
      return;
    }

    let cashFlowArray = cashFlow.map(vl => parseFloat(vl.cashflow));
    const guessRate = parseFloat(rate) / 100;

    const irrResult = calculateIRR(cashFlowArray, parseFloat(initialValue), guessRate);
    setResult(irrResult !== null ? irrResult.toFixed(2)+"%": "Unable to calculate the Internal Rate of Return based on provided data");
  };


  return (
    <div>
      <Tabs />
      <div className="custome-row">

        <div className="container-fluid">
          <div className='path-text'> <span className="ta-cursor" onClick={() => {
            navigate("/")
          }}>Calculator</span> &gt;<span style={{ color: "#0073bb" }}>IRR Calculator</span></div>
          {/* <div className='mt-4 fd-cal-text'>Fixed Deposit Calculator</div> */}
          <div className='row   '>
            {/* <div className='col-lg-2 col-12 mt-4 mb-5 pb-5 mb-lg-0'>

            </div> */}
            <div className='col-lg-8 col-12 mt-4'>
              <div className='background' id="content-sip">
                <div>
                  <div className='d-flex justify-content-between'>
                    <div>Initial Investment</div>
                    <div className="d-flex" style={{ width: 115 }}>
                      <span>
                        {investFlag === true ? <svg data-tooltip-id="invest" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                      </span>&nbsp;
                      <ReactTooltip id="invest" place="top" content={investErr} />
                      <input style={{ background: investFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: investFlag === true ? "#E05776" : '', paddingRight: 1 }} className={`form-control shadow-none sm-fl rd-max1 `} id={`${investFlag === true ? "fname1" : "fname"}`} value={"₹"} readOnly />
                      <input onChange={(e) => {
                        setInvestFlag(false)
                        if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                          if (e.target.value > 10000000) {
                            setinitialValue(10000000);
                          } else if (e.target.value == "") { setinitialValue(0); } else if (e.target.value == 0) { setinitialValue(0); } else {
                            if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                              let va = e.target.value.substring(1)
                              setinitialValue(va)
                            } else if (initialValue == 0 && e.target.value?.split("")[1] == '0') {
                              let va = e.target.value?.split("")[0];
                              setinitialValue(va)
                            } else {
                              setinitialValue(e.target.value)
                            }
                          }
                        }
                      }} value={initialValue} maxLength={10000000} style={{ background: investFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.1)', color: investFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${investFlag === true ? "fname1" : "fname"}`} name="fname" />
                    </div>
                  </div>
                  <Slider
                    min={0}
                    max={10000000}
                    value={initialValue}
                    onChange={(value) => {
                      setinitialValue(value)
                    }}
                    onChangeComplete={handleChangeComplete}
                  />
                </div>
                <div className='row   '>
              
                    {cashFlow?.map((val, x) => (
                      <>
                        <div>
                          <div className='d-flex justify-content-end'>
                            <div className="my-auto">Year {x+1}</div>
                            <div className="d-flex" style={{ width: 115 }}>
                              <span>
                                {cashFlowFlag === true ? <svg data-tooltip-id="invest" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                              </span>&nbsp;
                              <ReactTooltip id="invest" place="top" content={error} />
                              <input style={{ background: cashFlowFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: cashFlowFlag === true ? "#E05776" : '', paddingRight: 1 }} className={`form-control shadow-none sm-fl rd-max1 `} id={`${cashFlowFlag === true ? "fname1" : "fname"}`} value={"₹"} readOnly />
                              <input onChange={(e) => {
                                if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                                  const list = [...cashFlow];
                                  if (e.target.value > 10000000) {
                                    list[x]['cashflow']=10000000
                                    setcashFlow(list)
                                  } else if (e.target.value == "") { 
                                    list[x]['cashflow']=0
                                    setcashFlow(list)
                                  } else if (e.target.value == 0) { 
                                    list[x]['cashflow']=0
                                    setcashFlow(list)
                                  } else {
                                    if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                      let va = e.target.value.substring(1)
                                      list[x]['cashflow']=va
                                      setcashFlow(list)
                                    } else if (list[x].cashflow == 0 && e.target.value?.split("")[1] == '0') {
                                      let va = e.target.value?.split("")[0];
                                      list[x]['cashflow']=va
                                      setcashFlow(list)
                                    } else {
                                      list[x]['cashflow']=e.target.value
                                      setcashFlow(list)
                                    }
                                  }
                                }
                              }} value={val?.cashflow} maxLength={10000000} style={{ background: cashFlowFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.1)', color: cashFlowFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${cashFlowFlag === true ? "fname1" : "fname"}`} name="fname" />
                            </div>
                            <div className="my-auto mx-1">
                          {cashFlow?.length !== 1 && (
                             <div className="cash">
                             <img
                               src={Remove}
                               width={20}
                               height={20}
                               style={{
                                 background: "red",
                                 borderRadius: 40,
                                 padding: 4,
                               }}
                               onClick={(e) => RemoveMoreCashFlow(x, e)}
                             />
                           </div>
                          )}
                        </div>
                          </div>
                          <Slider
                            min={0}
                            max={10000000}
                            value={val.cashflow}
                            onChange={(value) => {
                              const list = [...cashFlow];
                              list[x]['cashflow']=value
                              setcashFlow(list)
                            }}
                            onChangeComplete={handleChangeComplete}
                          />
                        </div>
                      </>
                    ))}

<div>
                <div className='d-flex justify-content-between '>
                  <div> Guess <span id="opt">(Optional)</span></div>
                  <div className="d-flex" style={{ width: 115 }}>
                    <span >
                      {ratetFlag === true ? <svg data-tooltip-id="rate" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                    </span>&nbsp;
                    <ReactTooltip id="rate" place="top" content={ratetErr} />
                    <input onChange={(e) => {
                      let vl = e.target.value
                      if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else {
                        if (rate != "0" && e.target.value > 15) { setRate(15) } else if (e.target.value === "") {
                          setRate(0)
                        } else {
                          if (e.target.value.includes(".") == true && e.target.value?.split("")[1] == "0" && e.target.value?.split("")[0] != 1) { setRate(0) } else if (e.target.value.indexOf(0) == '0' && e.target.value >= 1) {
                            let va = e.target.value.substring(1)
                            setRate(va)
                          } else if (rate == 0 && e.target.value?.split("")[1] == '0') {

                            let va = e.target.value?.split("")[0];
                            setRate(va)
                          } else {
                            if (e.target.value.includes(".") == true && e.target.value >= 1) {
                              let srr = e.target.value.split(".");
                              if (srr[1]?.length > 2) {
                              } else {
                                let str = (e?.target?.value)
                                setRate(str)
                              }
                            } else if (e.target.value >= 1) {
                              let str = e?.target?.value
                              setRate(str)
                            }
                          }
                        }
                      }
                    }} value={rate} className='shadow-none form-control sm-bgr' style={{ color: ratetFlag === true ? "#E05776" : '', paddingRight: 4, textAlign: "right" }} type="text" id={`${ratetFlag === true ? "fname1" : "fname"}`} name="fname" />
                    <span style={{ background: ratetFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: ratetFlag === true ? "#E05776" : '', marginLeft: 2, paddingLeft: 0 }} className='shadow-none form-control sm-fr wdthcls' id={`${ratetFlag === true ? "fname1" : "fname"}`}>%</span>
                  </div>
                </div>
                <Slider
                  min={0.1}
                  max={15}
                  value={rate}
                  onChange={(value) => {
                    setRate(value?.toFixed(2))
                  }}
                  onChangeComplete={handleChangeComplete}
                  step={0.1}
                />
               
              </div>

                    <div className="row m-3">
                      <div className="d-flex justify-content-end">
                      <button className="d-flex add-more mx-2" onClick={handleCalculate}>
                         
                         <div
                           className="my-auto"
                           style={{
                             color: "white",
                             fontSize: 14,
                             fontWeight: 500,
                             marginLeft: 2,
                           }}
                         >
                           Calculate
                         </div>
                       </button>
                        <button className="d-flex add-more" onClick={addMoreCashflow}>
                          <div className="cash">
                            <img src={AddMore} width={20} height={20} />
                          </div>
                          <div
                            className="my-auto"
                            style={{
                              color: "white",
                              fontSize: 14,
                              fontWeight: 500,
                              marginLeft: 2,
                            }}
                          >
                            Cashflow
                          </div>
                        </button>
                        
                      </div>
                    </div>
                  
                  <div className="pb-5"></div>
                </div>



                <div className='d-flex justify-content-between pb-4'>
                  <div className='sip-count-text mt-4'><b>Internal Rate of Return</b></div>
                  <div className="mt-4"><b>{result=="Unable to calculate the Internal Rate of Return based on provided data"?<span style={{color:"gray" ,fontWeight:500}}>{result}.</span>:<>₹ {result}</>}</b></div>
                </div>
                {/* <div className='d-flex justify-content-between '>
                  <div className='sip-count-text'><b>Total Returns</b></div>
                  <div><b>₹ {returnVal}</b></div>
                </div> */}
                {/* <div className='d-flex justify-content-between pt-3 pb-4 mb-1'>
                  <div className='sip-count-text'><b>Total Value</b></div>
                  <div><b>₹ {totalVal}</b></div>
                </div> */}
              </div>
              {/* <hr style={{ border: '2px solid' }} /> */}
            </div>

            <div className='col-lg-2 col-12 mt-4 mb-5 pb-5 mb-lg-0'>

            </div>

            <div className="col-lg-8 col-12">
              {/* FAQs */}
            </div>
            <div className="pb-5"></div>
          </div>
        </div>
      </div>
    </div>
  )
}